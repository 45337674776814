const actions = {
  // Movement
  REHYDRATE_MOVEMENT_STATE_SUCCESS: "movement/REHYDRATE_MOVEMENT_STATE_SUCCESS",

  GET_MOVEMENT_LIST_REQUEST: "movement/GET_MOVEMENT_LIST_REQUEST",
  GET_MOVEMENT_LIST_SUCCESS: "GET_MOVEMENT_LIST_SUCCESS",
  GET_MOVEMENT_LIST_FAILURE: "GET_MOVEMENT_LIST_FAILURE",

  ADD_NEW_MOVEMENT_REQUEST: "movement/ADD_NEW_MOVEMENT_REQUEST",
  ADD_NEW_MOVEMENT_SUCCESS: "ADD_NEW_MOVEMENT_SUCCESS",
  ADD_NEW_MOVEMENT_FAILURE: "ADD_NEW_MOVEMENT_FAILURE",

  DELETE_MOVEMENT_REQUEST: "movement/DELETE_MOVEMENT_REQUEST",
  DELETE_MOVEMENT_SUCCESS: "DELETE_MOVEMENT_SUCCESS",
  DELETE_MOVEMENT_FAILURE: "DELETE_MOVEMENT_FAILURE",
   
  PUT_UPDATE_MOVEMENT_REQUEST: "movement/PUT_UPDATE_MOVEMENT_REQUEST",
  PUT_UPDATE_MOVEMENT_SUCCESS: "PUT_UPDATE_MOVEMENT_SUCCESS",
  PUT_UPDATE_MOVEMENT_FAILURE: "PUT_UPDATE_MOVEMENT_FAILURE"
};


export default actions;
